import { Link } from "gatsby-plugin-react-i18next"
import React from "react"

const Breadcrumb = ({ product, category }) => {
  const url = category.locale ==='tr' ? 'tel-bant' : 'conveyor-belts'

  return (
    <div className="my-1 text-blue">
        <Link to={"/" + url + "/" + category.slug} className="text-blue underline text-sm">{category.title}</Link> {">"} <span className="text-blue text-sm">{product}</span>
    </div>
  )
}

export default Breadcrumb

