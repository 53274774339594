import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import parse from "html-react-parser"
import Download from "../components/categories/Download"
import Documentation from "../components/categories/Documentation"
import Breadcrumb from "../components/Breadcrumb"

const ProductPage = ({ data, location }) => {
  const { t, i18n } = useTranslation()

  const product = data.strapiProduct
  const otherProducts = data.otherProducts.nodes

  const brand = (i18n.language === "tr" ? data.site.siteMetadata.titleTr : data.site.siteMetadata.titleEn) || `tbant`

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.category.title + " " + product.title,
    "image": product.pictures[0]?.localFile.url,
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "0",
      "availability": "https://schema.org/InStock",
      "url": data.site.siteMetadata.siteUrl + location.pathname,
      "priceValidUntil": ""
    },
    "description": product.title + " - " + product.description,
    "brand": brand,
    "category": product.category.title
  }

  const seo = {
    title: product.metaTitle,
    description: product.metaDescription,
    keywords: product.metaKeywords,
    schema: schema
  }

  const image = getImage(product.pictures[0]?.localFile)
  const tech_img = getImage(product.technical_drawing[0]?.localFile)
  const size_img = getImage(product.size_drawing?.localFile)

  const whatsappLink = `https://api.whatsapp.com/send?phone=905064772979&text=${t("productWhatsappText")} ${location.href}` 
  
  return (
    <Layout className="bg-lightGray" navbarTheme="dark">
      <Seo {...seo} />
      {/* <header className="container max-w-6xl text-center py-2 bg-black text-white ">       
        
          <h1 className="text-5xl mt-10 font-bold font-serif ">{product.title}</h1>
       
      </header>
      <main className="mt-8 ">
     
      </main> */}

      <div className="bg-lightGray divide-y-[0.5px] divide-blue">
        <div>
          <div className="max-w-7xl px-6 md:px-10 mx-auto">
            <div className="md:flex justify-between pb-14 lg:pb-20 pt-10">
              <div className="md:w-2/5">
                <GatsbyImage
                  image={image}
                  alt={product.pictures[0]?.alternativeText}
                />
              </div>
              <div className="flex flex-col md:w-1/2">
                <div className="py-10 md:py-0">
                  <h1 className="text-4xl font-bold text-darkBlue">
                    {product.title}
                  </h1>
                  <Breadcrumb category={product.category} product={product.title} />
                  <div className="py-4 pb-12 max-w-md">
                    <p className="text-darkBlue text-lg">
                      {product.description}
                    </p>
                  </div>
                  <div className="mt-2">
                    <Link to="/quote" state={{ strapi_id: product.strapi_id }} className="inline-block p-2.5 transition bg-purple text-white border border-transparent hover:border-white hover:bg-darkBlue text-sm mr-5 mb-4">
                      {t("productQuoteCTA")}
                    </Link>
                    <a 
                      href={whatsappLink}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-block transition bg-lightGray border border-purple text-purple hover:text-white hover:bg-darkBlue text-sm p-2.5 mb-4"
                    >
                      <div className="flex">
                        <div className="mr-2">
                          <svg
                            className="inline-block"
                            width="20"
                            height="19"
                            viewBox="0 0 20 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19.3202 9.02272C19.1946 4.06538 15.1097 0.0864258 10.0826 0.0864258C5.11661 0.0864258 1.06691 3.97443 0.85083 8.85476C0.846018 8.98855 0.841686 9.12234 0.841686 9.25661C0.839334 10.9273 1.29687 12.5665 2.16416 13.9945L0.5 18.9133L5.61807 17.2862C6.98612 18.0374 8.52191 18.4304 10.0826 18.4287C15.1877 18.4287 19.3226 14.3227 19.3226 9.25661C19.3245 9.17864 19.3245 9.10068 19.3202 9.02272ZM10.0826 16.9662C8.56357 16.9695 7.07717 16.5256 5.80865 15.6899L2.82347 16.6414L3.7927 13.777C2.83037 12.4662 2.3119 10.8822 2.31286 9.25613C2.31276 9.00633 2.32577 8.7567 2.35184 8.50827C2.73251 4.60534 6.05745 1.54653 10.0826 1.54653C14.1631 1.54653 17.5155 4.68331 17.8273 8.65505C17.8442 8.85428 17.8528 9.05256 17.8528 9.25613C17.8528 13.5094 14.3657 16.9662 10.0826 16.9662Z"
                              fill="#25D366"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.318 11.1071C14.0894 10.9949 12.9773 10.4492 12.7694 10.3761C12.5615 10.3029 12.4103 10.2635 12.2592 10.4887C12.1081 10.7139 11.6707 11.2192 11.5407 11.367C11.4108 11.5147 11.277 11.5354 11.0479 11.4228C10.8189 11.3102 10.0912 11.0768 9.22593 10.3063C8.55555 9.71387 8.0974 8.97804 7.96747 8.7533C7.83753 8.52855 7.95447 8.4068 8.06708 8.29467C8.17055 8.1912 8.29135 8.03094 8.40829 7.90149C8.43861 7.86251 8.4646 7.82738 8.49106 7.79321C8.54437 7.70658 8.59258 7.61692 8.63544 7.52467C8.7134 7.37356 8.67442 7.24362 8.61811 7.13101C8.56181 7.0184 8.10751 5.91056 7.91742 5.46108C7.72732 5.01159 7.53867 5.09148 7.40489 5.09148C7.2711 5.09148 7.12336 5.06982 6.97176 5.06982C6.85697 5.07263 6.74398 5.09903 6.63983 5.14738C6.53567 5.19573 6.44259 5.265 6.36635 5.35087C6.15845 5.57562 5.57422 6.12087 5.57422 7.22774C5.57823 7.48725 5.61757 7.745 5.69116 7.99389C5.92072 8.78506 6.41304 9.43763 6.50014 9.55072C6.61275 9.69799 8.0666 12.0426 10.3679 12.9474C12.6741 13.8425 12.6741 13.5441 13.0889 13.5051C13.5037 13.4662 14.4301 12.9642 14.6164 12.4368C14.8026 11.9093 14.8089 11.4632 14.7507 11.3684C14.6924 11.2736 14.5432 11.2207 14.318 11.1071Z"
                              fill="#25D366"
                            />
                          </svg>
                        </div>
                        <span className="inline-block self-center">
                          {t("whatsappCTA")}
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="mt-10">
                  <Download link={product.pdf.url} size={product.pdf.size} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="max-w-7xl px-6 md:px-10 mx-auto">
            <div className="py-28 lg:py-32">
              <h2 className="text-2xl text-blue font-bold pb-8 md:pb-16">
                {product.title} - {t("technicalDrawing")} ({product.category.title})
              </h2>
              <div className="md:flex justify-between space-y-3 md:space-y-0">
                <div>
                  <GatsbyImage
                    image={tech_img}
                    alt={product.technical_drawing[0]?.alternativeText}
                    objectFit="contain"
                  />
                </div>

                <div>
                  <GatsbyImage
                    image={size_img}
                    alt={product.size_drawing?.alternativeText}
                    objectFit="contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="dimensions">
          <div className="max-w-7xl p-10 mx-auto py-28 lg:py-32">
            <h6 className="text-xl text-darkBlue font-bold">{t("dimensionsTitle")}</h6>
            <div className="dimension-table">
              {parse(product.dimensions.data?.childMarkdownRemark.html)}
            </div>
          </div>
        </div> */}

        <div>
          <div className="py-28 lg:py-32 max-w-7xl mx-auto">
            <div className="px-6 md:px-10 w-full mx-auto">
              <h2 className="text-xl text-darkBlue font-bold">
                {product.title} - {t("dimensionsTitle")}: ({product.category.title})
              </h2>
            </div>
            <div className="dimension-table ml-auto md:mr-auto px-6 md:px-10 overflow-x-scroll">
              {parse(product.dimensions?.data?.childMarkdownRemark.html)}
            </div>
          </div>
        </div>

        <div>
          <div className="max-w-7xl mx-auto px-6 md:px-10">
              <div className="py-28 md:py-32 md:flex justify-between">
                <p className="md:w-1/2 text-blue text-xl font-bold">
                  {t("documentations")}
                </p>
                <div className="md:w-1/2 pt-20 md:pt-0">
                  <Documentation title={product.category.title} pdf={product.category.documentation[0].url} />
                  <Documentation title={product.category.title + " " + t("dimensionsTitle")} pdf={product.pdf.url} />
                  <Documentation title={t("generalDocument")} pdf={t("generalDocumentPdfLink")} />
                </div>
              </div>
          </div>
        </div>

        <div>
          <div className="max-w-7xl mx-auto px-6 md:px-10 pt-28 lg:pt-32 pb-10 lg:pb-16">
            <p className="text-3xl md:text-5xl font-semibold text-darkBlue">
              {t("typesTitle")}: {product.category.title}
            </p>
            <div className="flex flex-wrap mt-10 md:mt-8 md:-mx-6 lg:-mx-10 box-border">
              {otherProducts.map(product => {
                const image = getImage(product.pictures[0]?.localFile)
                return (
                  <Link
                    key={product.id}
                    to={"/"+product.category.slug + "/" + product.slug}
                    className="block w-full md:w-1/3 mb-6 md:p-6 lg:p-10"
                  >
                    <p className="text-darkBlue font-bold text-lg mb-5">
                      {product.title}
                    </p>
                    <div className="overflow-hidden">
                      <GatsbyImage
                        image={image}
                        alt={product.pictures[0]?.alternativeText}
                        className="transition hover:scale-110"
                      />
                    </div>
                    <p className="text-darkBlue text-lg mt-7">
                      {product.description}
                    </p>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String, $language: String, $categorySlug: String) {
    strapiProduct(slug: { eq: $slug }, locale: { eq: $language }) {
      id
      strapi_id
      slug
      metaTitle
      metaDescription
      metaKeywords
      title
      description
      locale
      category {
        slug
        locale
        title
        documentation {
          alternativeText
          url
        }
      }
      materials {
        title
      }
      pictures {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(aspectRatio: 1)
          }
        }
      }
      technical_drawing {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(height: 400)
          }
        }
      }
      size_drawing {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(height: 400)
          }
        }
      }
      dimensions {
        __typename
        data {
          id
          childMarkdownRemark {
            html
          }
        }
      }
      pdf {
        url
        size
      }
    }

    otherProducts: allStrapiProduct(
      filter: {
        category: { slug: { eq: $categorySlug } }
        slug: { ne: $slug }
        locale: { eq: $language }
      }
    ) {
      nodes {
        id
        strapi_id
        slug
        title
        description
        category{
          slug
        }
        pictures {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.3)
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTr
        titleEn
        siteUrl
      }
    }
  }
`

export default ProductPage
